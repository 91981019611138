<template>
    <div :class="['title',isFixed ? 'title-fixed': '',!isLtr ? 'title-rtl' : '']">

        <button v-if="backButton" @click="goToBack" class="btn btn-back btn-icon btn-round">
            <span class="material-icons-outlined" aria-hidden="true">{{nextArrow}}</span>
        </button>

        <div class="title-bar">
            <div v-if="showButtons" class="btn-group">
                <router-link :to="karteLink" :class="['btn', 'map-list-toggle', showMapOrList ? 'btn-active' : '']">Karte</router-link>
                <router-link :to="listeLink" :class="['btn', 'map-list-toggle', !showMapOrList ? 'btn-active' : '']">Liste</router-link>
            </div>

            <button @click="activateTermin" v-else-if="showTermin" :class="['btn btn-w-icon icon-left title-bar-btn', showTerminSelection ? 'btn-active' : '']">
                <span class="material-icons-outlined" aria-hidden="true">{{terminButtonIcon}}</span>
                <span class="label">{{terminButtonText}}</span>
            </button>

            <button @click="activateFilter" :class="['btn btn-w-icon icon-left title-bar-btn', hasBreadcrumb ? 'ml-auto' : '', showFilterSelection ? 'btn-active': '']">
                <span class="material-icons-outlined" aria-hidden="true">{{filterButtonIcon}}</span>
                <span class="label">{{filterButtonText}}</span>
            </button>

            <button @click="activateSearch" :class="['btn btn-w-icon icon-left title-bar-btn', showTextSearch ? 'btn-active' : '']">
                <span class="material-icons-outlined" aria-hidden="true">{{searchButtonIcon}}</span>
                <span class="label">{{searchButtonText}}</span>
            </button>
        </div>

        <time-filters :initialValueSet="dateTimeSelection" v-show="showTerminSelection" @changedTimeFilters="changedTimeFilters"/>

        <content-filters v-if="showFilterSelection" :filterMenu="filterMenu" 
        :contentCount="contentCount" :filters="filters" :isLtr="isLtr" :showActiveFilters="showActiveFilters" :showToggleFilters="showToggleFilters"
        :selectedFilters="selectedFilters" @onClose="closeContentFilters" @resetAllFilters="resetAllFilters"
        @filteringFinished="contentFilteringFinished" @getNumberOfResults="getNumberOfResults" @sliderChanged="sliderChanged"/>

        <keyword-filter v-if="showTextSearch" :initialValue="initialKeywordSearchValue" @searchWordChanged="searchWordChanged"/>

        <content-type-filter v-if="showContentTypeSelect" :initialValue="initialContentType" :contentTypeSelectValues="contentTypeSelectValues" @contentTypeChanged="contentTypeChanged"/>

    </div>
</template>

<script>
    export default {
        name: 'TitleBar',
        components: {
            TimeFilters: () => import('./components/TimeFilters'),
            KeywordFilter: () => import('./components/KeywordFilter'),
            ContentFilters: () => import('./components/ContentFilters'),
            ContentTypeFilter: () => import('./components/ContentTypeFilter'),
        },
        props: {
            showButtons: {
                type: Boolean,
            default: false,
            },
            showActiveFilters: {
                type: Boolean,
            default: false,
            },
            showToggleFilters: {
                type: Boolean,
            default: false,
            },
            karteLink: {
                type: String,
            default: '#!',
            },
            listeLink: {
                type: String,
            default: '#!',
            },
            showTermin: {
                type: Boolean,
            default: false,
            },
            showContentTypeSelect: {
                type: Boolean,
            default: false,
            },
            showMapOrList: {
                type: Boolean,
            default: true,
            },
            hasBreadcrumb: {
                type: Boolean,
            default: false,
            },
            filterMenu: {
                type: String,
            default: 'places',
                validator: filterMenu => ['places', 'offers', 'kita', 'schul', 'bookmark'].indexOf(filterMenu) !== -1
            },
            filters: {
                type: Array,
                required: false,
            default: [],
            },
            selectedFilters: {
                type: Map,
            default: new Map(),
            },
            backButton: {
                type: Boolean,
            default: false,
            },
            isLtr: {
                type: Boolean,
            default: true,
            },
            isFixed: {
                type: Boolean,
            default: false,
            },
            initialKeywordSearchValue: {
                type: String,
            default: '',
            },
            contentTypeSelectValues: {
                type: Array,
            default: null
            },
            initialContentType: {
                type: String,
            default: 'Angebote',
            },
            contentCount: {
                tyle: Number,
            default: 0
            },
            dateTimeSelection: {
                type: Object,
            default: null
            }
        },
        data() {
            return {
                showTextSearch: false,
                showFilterSelection: false,
                showTerminSelection: false,
                searchButtonText: "Suche",
                filterButtonText: "Filter",
                terminButtonText: "Zeitraum",
            }
        },
        computed: {
            filterButtonIcon(){
                if(this.filterButtonText === "Filter") {
                    return 'filter_alt';
                }
                return 'close';
            },
            searchButtonIcon(){
                if(this.searchButtonText === "Suche") {
                    return 'search';
                }
                return 'close';
            },
            terminButtonIcon(){
                if(this.terminButtonText === "Zeitraum") {
                    return 'schedule';
                }
                return 'close';
            },
            nextArrow() {
                return this.isLtr ? 'arrow_back' : 'arrow_forward';
            },
        },
        methods: {
            sliderChanged() {
                this.$emit('sliderChanged');
            },
            closeDetailFilterSections() {
                this.showFilterSelection = false;
                this.showTerminSelection = false;
                this.showTextSearch = false;
                this.searchButtonText = "Suche";
                this.filterButtonText = "Filter";
                this.terminButtonText = "Zeitraum";
            },
            activateSearch() {
                this.showFilterSelection = false;
                this.showTerminSelection = false;
                this.showTextSearch = !this.showTextSearch;
                this.searchButtonText === "Suche" ? this.searchButtonText="Schließen":this.searchButtonText="Suche";
                this.filterButtonText = "Filter";
                this.terminButtonText = "Zeitraum";
            },
            activateFilter() {
                this.showTerminSelection = false;
                this.showTextSearch = false;
                this.showFilterSelection = !this.showFilterSelection;
                this.filterButtonText === "Filter" ? this.filterButtonText="Schließen":this.filterButtonText="Filter";
                this.searchButtonText = "Suche";
                this.terminButtonText = "Zeitraum";
            },
            activateTermin() {
                this.showTextSearch = false;
                this.showFilterSelection = false;
                this.showTerminSelection = !this.showTerminSelection;
                this.terminButtonText === "Zeitraum" ? this.terminButtonText="Schließen":this.terminButtonText="Zeitraum";
                this.searchButtonText = "Suche";
                this.filterButtonText = "Filter";
            },
            contentTypeChanged(value){
                this.$emit('contentTypeChanged',value);
            },
            closeContentFilters(){
                this.activateFilter();
                this.getNumberOfResults(this.selectedFilters);
            },
            contentFilteringFinished(map){
                this.activateFilter();
                this.$emit('contentFilteringFinished',map);
            },
            getNumberOfResults(map){
                this.$emit('getNumberOfResults',map);
            },
            changedTimeFilters(timeObject){
                this.closeDetailFilterSections();
                this.$emit('changedTimeFilters',timeObject);
            },
            searchWordChanged(word){
                this.closeDetailFilterSections();
                this.$emit('searchWordChanged',word);
            },
            resetAllFilters(){
                this.$emit('resetAllFilters');
            },
            goToBack() {
                this.$router.go(-1);
            }
        },
    }
</script>

<style lang="scss" scoped>
    @import '@/scss/_variables.scss';
    @import '@/scss/_mixins.scss';

    .btn-w-icon{

        .label{
         @media (max-width: 420px) {
            font-size: 14px;
        }

        @media (max-width: 370px) {
            font-size: 13px;
        }
    }

    .material-icons-outlined{
     @media (max-width: 420px) {
        margin-right: 0px!important;
    }
}
}

.form-check, .form-check-label{
    width: 100%!important;
    cursor: pointer;
}

.btn-active {
    color: #FFFF !important;
    background: $primary !important;
}

.title {
    position: relative;
    display: flex;
    align-items: center;
    background: $white-color;
    height: $title-bar-size;
    max-height: $title-bar-size;
    width: 100%;
    z-index: 9999;

    &-fixed {
        position: absolute;
        top: 0;
    }

    .btn {
        &-back {
            position: absolute;
            margin-left: 15px;
        }
    }

    @include responsive($breakpoint) {
        display: none;
    }

    &-bar {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .btn {
            padding: 0 20px;
            border-radius: 0;
            border: none;
            border-top: 10px solid $white-color;
            border-bottom: 10px solid $white-color;
            text-transform: unset;
            color: $dark-color;
            background-color: #FFFFFF;
            box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.1);
            border-radius: 0px 0px 0px 20px;

            &:first-child {
                margin: 3px 0px 3px 3px;
            }

            &:last-child {
                margin: 3px 0px 3px 0px;
            }

            &-active {
                background: #F9F9F9;
                color: $dark-color;
                border-bottom-color: $primary;
                border-top-color: $primary;

                .label {
                    @media(max-width: 370px){
                        display:none;
                    }
                }
            }
        }

        .btn-group {
            background-color: $white-color;
            border-radius: 100px;
            margin-left: 15px;
            box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.1);
            
            .btn {
                border: none;
                padding: 0 0.75rem;
                height: 30px;
                background-color: transparent;
                color: $dark-color;
                font-weight: 400;
                line-height: 30px;
                box-shadow: none;
                text-align: center;

                &-active {
                    background-color: $primary;
                    color: #FFFF;
                    border-radius: 100px !important;
                    font-weight: 700;

                    &:focus {
                        text-decoration: none;
                    }
                }
            }
        }
    }

    .map-list-toggle {
        overflow: hidden;
    }

    .title-bar-btn {
        flex: 0 0 30%;

        @media(max-width: 400px){
            flex: 0 0 29%;
        }

        &:first-child {
            margin-left: 15px;
        }

        &:last-child {
            margin-right: 15px;
        }

        @media(max-width: 500px){
            padding: 0px 10px;
        }

        @media(max-width: 380px){
            min-width: 80px;
            padding: 0px 5px;
        }

        .label {
            margin-left: 3px;
        }

    }



    &-rtl {
        .btn {
            &-back {
                margin-left: 0;
                margin-right: 15px;
            }
        }

        .title-bar {
            .btn-group {
                margin-left: 0;
                margin-right: 15px;
            }
        }
    }
}
</style>
